import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/policy',
        name: 'policy',

        component: () => import(/* webpackChunkName: "about" */ '../views/PolicyView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
