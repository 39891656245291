<template>
  <footer class="footer flex ai-center flex-column">
    <div class="footer__logo">
      <svg
        width="186"
        height="50"
        viewBox="0 0 186 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.193405 38.8793V33.0771L6.96258 38.8793H0.193405Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M66.7247 38.8793V33.0771L59.7621 38.8793H66.7247Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M33.4591 32.4968L26.6899 38.299H40.2282L33.4591 32.4968Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 1.06856V7.9151C0 8.05048 0.0580215 8.2052 0.174064 8.30191L2.86239 10.7001C3.09448 10.9129 3.09448 11.261 2.86239 11.4737L0.174064 13.7366C0.0580215 13.8333 0 13.988 0 14.1234V27.2749C0 27.4103 0.0580215 27.5457 0.154724 27.6424L11.2948 38.1443C11.3916 38.241 11.5269 38.2797 11.643 38.2797H22.1449C22.2802 38.2797 22.4156 38.2216 22.5123 38.1249L33.1303 27.6231C33.227 27.5264 33.3623 27.4683 33.4977 27.4683H33.9039C34.0393 27.4683 34.1746 27.5264 34.2907 27.6231L44.193 38.0863C44.2897 38.183 44.4251 38.241 44.5798 38.241H55.1978C55.3331 38.241 55.4685 38.183 55.5652 38.1056L66.57 27.6037C66.6667 27.507 66.7247 27.3716 66.7247 27.2362V14.0847C66.7247 13.9687 66.686 13.8333 66.5893 13.7366L64.5005 11.4157C64.3265 11.2223 64.3265 10.9322 64.5005 10.7388L66.6087 8.28257C66.686 8.18586 66.7247 8.06982 66.7247 7.95378V1.02988C66.7247 0.565709 66.1638 0.352964 65.835 0.662412L55.7586 10.7195C55.5652 10.9129 55.5652 11.2417 55.7586 11.4351L62.992 18.6877C63.1854 18.8812 63.1854 19.2099 62.992 19.4227L49.8405 31.994C49.6277 32.1874 49.2989 32.1874 49.1055 31.9747L37.1337 19.2099C37.037 19.1132 36.9984 18.9972 36.9984 18.8618V18.3203C36.9984 18.1849 37.0564 18.0495 37.1531 17.9528L43.9996 11.4351C44.2124 11.2417 44.2124 10.9129 44.019 10.7195L33.6718 0.159559C33.459 -0.0531863 33.1303 -0.0531863 32.9175 0.159559L22.7251 10.7001C22.5317 10.9129 22.5317 11.2417 22.7638 11.4351L30.2679 17.9528C30.3839 18.0495 30.4419 18.1849 30.4419 18.3396V18.8618C30.4419 18.9972 30.3839 19.1326 30.2872 19.2293L17.4064 32.2067C17.3097 32.3035 17.1744 32.3615 17.039 32.3615H16.3814C16.246 32.3615 16.1106 32.3035 16.0139 32.2067L3.44261 19.2293C3.3459 19.1326 3.30722 19.0165 3.30722 18.8812V18.3396C3.30722 18.2042 3.36525 18.0689 3.46195 17.9722L10.4245 11.4544C10.6373 11.261 10.6373 10.9322 10.4439 10.7388L0.889663 0.720433C0.580215 0.372304 0 0.60439 0 1.06856Z"
          fill="white"
        />
        <path
          d="M66.7247 45.1648H0.193405V49.9999H66.7247V45.1648Z"
          fill="white"
        />
        <path
          d="M75.7567 17.1405H81.7909L82.9514 19.9255H86.1039L80.3017 6.92871H77.3233L71.5211 19.9255H74.5963L75.7567 17.1405ZM78.7738 9.88781L80.8432 14.8583H76.7237L78.7738 9.88781Z"
          fill="white"
        />
        <path
          d="M88.7535 18.2622C89.3917 18.8618 90.1267 19.3453 90.9777 19.6741C91.848 20.0029 92.7763 20.1576 93.782 20.1576C94.9231 20.1576 95.9482 19.9642 96.8378 19.558C97.7468 19.1712 98.5204 18.591 99.1393 17.8367L97.2053 16.0574C96.7605 16.5796 96.2576 16.9664 95.7161 17.2178C95.1745 17.4693 94.575 17.5853 93.9368 17.5853C93.3372 17.5853 92.7763 17.4886 92.2735 17.2952C91.7706 17.1018 91.3258 16.8117 90.9583 16.4442C90.5908 16.0768 90.3007 15.6319 90.088 15.1291C89.8946 14.6262 89.7979 14.0653 89.7979 13.4464C89.7979 12.8276 89.8946 12.2667 90.088 11.7638C90.3007 11.261 90.5908 10.8161 90.9583 10.4487C91.3258 10.0812 91.7706 9.7911 92.2735 9.59769C92.7763 9.40429 93.3372 9.30758 93.9368 9.30758C94.575 9.30758 95.1745 9.44297 95.7161 9.69439C96.2576 9.94582 96.7605 10.3133 97.2053 10.8355L99.1393 9.05616C98.5204 8.30188 97.7468 7.741 96.8378 7.35419C95.9288 6.96738 94.9231 6.75464 93.8014 6.75464C92.7957 6.75464 91.848 6.9287 90.997 7.25749C90.146 7.58628 89.3917 8.05045 88.7535 8.65001C88.1153 9.24956 87.6318 9.96516 87.2643 10.7775C86.9162 11.5898 86.7421 12.4988 86.7421 13.4658C86.7421 14.4522 86.9162 15.3418 87.2643 16.1541C87.6124 16.9277 88.1153 17.6433 88.7535 18.2622Z"
          fill="white"
        />
        <path
          d="M112.252 8.61124C111.614 8.01169 110.879 7.52817 110.009 7.19939C109.158 6.8706 108.21 6.69653 107.185 6.69653C106.179 6.69653 105.232 6.8706 104.361 7.19939C103.491 7.52817 102.737 8.01169 102.098 8.61124C101.46 9.2108 100.957 9.9264 100.59 10.7387C100.242 11.551 100.068 12.4407 100.068 13.4077C100.068 14.3747 100.242 15.2644 100.59 16.0767C100.957 16.889 101.441 17.6046 102.079 18.2041C102.717 18.8037 103.472 19.2872 104.342 19.616C105.212 19.9448 106.179 20.1188 107.204 20.1188C108.21 20.1188 109.158 19.9448 110.009 19.616C110.879 19.2872 111.633 18.823 112.252 18.2235C112.89 17.6239 113.393 16.9083 113.761 16.096C114.128 15.2644 114.302 14.3747 114.302 13.4077C114.302 12.4213 114.128 11.5317 113.761 10.7387C113.393 9.94574 112.89 9.23014 112.252 8.61124ZM110.937 15.1096C110.744 15.6125 110.454 16.0573 110.086 16.4248C109.719 16.7923 109.274 17.0824 108.79 17.2758C108.307 17.4692 107.785 17.5659 107.185 17.5659C106.585 17.5659 106.063 17.4692 105.56 17.2758C105.058 17.0824 104.632 16.7923 104.265 16.4248C103.897 16.0573 103.626 15.6125 103.414 15.1096C103.22 14.5875 103.123 14.0266 103.123 13.427C103.123 12.8081 103.22 12.2473 103.414 11.7444C103.626 11.2415 103.916 10.7967 104.284 10.4292C104.651 10.0618 105.077 9.77167 105.56 9.57827C106.063 9.38486 106.585 9.28816 107.185 9.28816C107.785 9.28816 108.307 9.38486 108.81 9.57827C109.312 9.77167 109.738 10.0618 110.086 10.4292C110.454 10.7967 110.744 11.2415 110.937 11.7444C111.15 12.2473 111.246 12.8081 111.246 13.427C111.246 14.0459 111.13 14.6068 110.937 15.1096Z"
          fill="white"
        />
        <path
          d="M127.473 19.9255V22.6719H130.239V17.4693H128.382V6.92871H125.384V17.4693H119.602V6.92871H116.604V19.9255H125.384H127.473Z"
          fill="white"
        />
        <path
          d="M135.209 6.92871H132.212V19.9255H135.209V6.92871Z"
          fill="white"
        />
        <path
          d="M141.205 17.1405H147.239L148.4 19.9255H151.552L145.75 6.92871H142.771L136.969 19.9255H140.044L141.205 17.1405ZM144.222 9.88781L146.291 14.8583H142.153L144.222 9.88781Z"
          fill="white"
        />
        <path
          d="M164.123 19.9255V22.6719H166.889V17.4693H165.032V6.92871H162.054V17.4693H156.252V6.92871H153.273V19.9255H162.054H164.123Z"
          fill="white"
        />
        <path
          d="M171.879 6.92871H168.881V19.9255H171.879V6.92871Z"
          fill="white"
        />
        <path
          d="M179.867 6.92871C178.725 6.92871 177.739 7.12212 176.888 7.50893C176.037 7.89574 175.399 8.45661 174.915 9.17221C174.451 9.88781 174.219 10.7581 174.219 11.7638C174.219 12.7502 174.432 13.5818 174.877 14.2974C175.322 15.013 175.94 15.5352 176.753 15.922C176.83 15.9607 176.907 15.9801 176.985 16.0187L174.277 19.9255H177.449L179.751 16.4636H182.497V19.9255H185.475V6.92871H179.867ZM177.971 9.96517C178.435 9.57836 179.093 9.36561 179.944 9.36561H182.497V14.0847H179.867C179.016 14.0847 178.397 13.8719 177.952 13.4658C177.507 13.0596 177.294 12.4794 177.294 11.7252C177.314 10.9515 177.526 10.3713 177.971 9.96517Z"
          fill="white"
        />
        <path
          d="M84.6727 29.5569H81.5008L78.0969 34.8175H75.9308V29.5569H72.9523V42.5537H75.9308V37.3124H78.0002L81.3848 42.5537H84.808L80.4371 35.8425L84.6727 29.5569Z"
          fill="white"
        />
        <path
          d="M97.4374 31.2397C96.7991 30.6401 96.0642 30.1566 95.1939 29.8278C94.3429 29.499 93.3952 29.325 92.3702 29.325C91.3645 29.325 90.4168 29.499 89.5465 29.8278C88.6761 30.1566 87.9219 30.6401 87.2836 31.2397C86.6454 31.8392 86.1425 32.5548 85.7751 33.3671C85.4269 34.1794 85.2529 35.0691 85.2529 36.0361C85.2529 37.0031 85.4269 37.8928 85.7751 38.7051C86.1425 39.5174 86.6261 40.233 87.2643 40.8325C87.9025 41.4321 88.6568 41.9156 89.5271 42.2444C90.3974 42.5732 91.3645 42.7472 92.3895 42.7472C93.3952 42.7472 94.3429 42.5732 95.1939 42.2444C96.0642 41.9156 96.8185 41.4514 97.4374 40.8519C98.0756 40.2523 98.5785 39.5367 98.9459 38.7244C99.3134 37.8928 99.4875 37.0031 99.4875 36.0361C99.4875 35.0497 99.3134 34.1601 98.9459 33.3671C98.5785 32.5742 98.0756 31.8586 97.4374 31.2397ZM96.1222 37.7381C95.9288 38.2409 95.6387 38.6857 95.2712 39.0532C94.9038 39.4207 94.4589 39.7108 93.9754 39.9042C93.4919 40.0976 92.9697 40.1943 92.3702 40.1943C91.7706 40.1943 91.2484 40.0976 90.7456 39.9042C90.2427 39.7108 89.8172 39.4207 89.4498 39.0532C89.0823 38.6857 88.8115 38.2409 88.5988 37.7381C88.4054 37.2159 88.3087 36.655 88.3087 36.0554C88.3087 35.4365 88.4054 34.8757 88.5988 34.3728C88.8115 33.87 89.1016 33.4251 89.4691 33.0577C89.8366 32.6902 90.2621 32.4001 90.7456 32.2067C91.2484 32.0133 91.7706 31.9166 92.3702 31.9166C92.9697 31.9166 93.4919 32.0133 93.9948 32.2067C94.4976 32.4001 94.9231 32.6902 95.2712 33.0577C95.6387 33.4251 95.9288 33.87 96.1222 34.3728C96.335 34.8757 96.4317 35.4365 96.4317 36.0554C96.4317 36.6743 96.335 37.2352 96.1222 37.7381Z"
          fill="white"
        />
        <path
          d="M110.434 30.1371C109.583 29.7503 108.597 29.5569 107.417 29.5569H101.789V42.5537H104.806V38.9757H107.417C108.578 38.9757 109.583 38.7823 110.434 38.4148C111.285 38.028 111.923 37.4865 112.388 36.7902C112.852 36.094 113.084 35.243 113.084 34.276C113.084 33.2896 112.852 32.458 112.388 31.7424C111.923 31.0461 111.266 30.5046 110.434 30.1371ZM109.332 35.9199C108.868 36.3067 108.171 36.5195 107.243 36.5195H104.787V32.0131H107.243C108.171 32.0131 108.868 32.2065 109.332 32.6127C109.796 32.9995 110.028 33.5604 110.028 34.276C110.009 34.9722 109.796 35.5331 109.332 35.9199Z"
          fill="white"
        />
        <path
          d="M126.506 31.2397C125.868 30.6401 125.133 30.1566 124.263 29.8278C123.412 29.499 122.464 29.325 121.439 29.325C120.433 29.325 119.486 29.499 118.615 29.8278C117.745 30.1566 116.991 30.6401 116.352 31.2397C115.714 31.8392 115.211 32.5548 114.844 33.3671C114.496 34.1794 114.322 35.0691 114.322 36.0361C114.322 37.0031 114.496 37.8928 114.844 38.7051C115.211 39.5174 115.695 40.233 116.333 40.8325C116.971 41.4321 117.726 41.9156 118.596 42.2444C119.466 42.5732 120.433 42.7472 121.458 42.7472C122.464 42.7472 123.412 42.5732 124.263 42.2444C125.133 41.9156 125.887 41.4514 126.506 40.8519C127.144 40.2523 127.647 39.5367 128.015 38.7244C128.382 37.8928 128.556 37.0031 128.556 36.0361C128.556 35.0497 128.382 34.1601 128.015 33.3671C127.647 32.5742 127.144 31.8586 126.506 31.2397ZM125.172 37.7381C124.978 38.2409 124.688 38.6857 124.321 39.0532C123.953 39.4207 123.508 39.7108 123.025 39.9042C122.541 40.0976 122.019 40.1943 121.42 40.1943C120.82 40.1943 120.298 40.0976 119.795 39.9042C119.292 39.7108 118.867 39.4207 118.499 39.0532C118.132 38.6857 117.861 38.2409 117.648 37.7381C117.455 37.2159 117.358 36.655 117.358 36.0554C117.358 35.4365 117.455 34.8757 117.648 34.3728C117.861 33.87 118.151 33.4251 118.519 33.0577C118.886 32.6902 119.311 32.4001 119.795 32.2067C120.298 32.0133 120.82 31.9166 121.42 31.9166C122.019 31.9166 122.541 32.0133 123.044 32.2067C123.547 32.4001 123.973 32.6902 124.321 33.0577C124.688 33.4251 124.978 33.87 125.172 34.3728C125.384 34.8757 125.481 35.4365 125.481 36.0554C125.5 36.6743 125.384 37.2352 125.172 37.7381Z"
          fill="white"
        />
        <path
          d="M131.902 34.8368C131.863 35.9586 131.786 36.9063 131.67 37.6799C131.554 38.4535 131.341 39.0531 131.051 39.4786C130.761 39.8847 130.374 40.0975 129.871 40.0975C129.678 40.0975 129.504 40.0781 129.33 40.0201L129.136 42.573C129.427 42.6311 129.678 42.6891 129.91 42.7278C130.161 42.7664 130.394 42.7858 130.606 42.7858C131.361 42.7858 131.979 42.6117 132.482 42.2442C132.985 41.8768 133.372 41.3546 133.643 40.6583C133.914 39.9621 134.146 39.1304 134.281 38.1247C134.416 37.1384 134.513 35.9973 134.532 34.7208L134.629 32.0131H139.097V42.5537H142.075V29.5569H132.096L131.902 34.8368Z"
          fill="white"
        />
        <path
          d="M148.322 37.1384H154.356V34.7982H148.322V31.9744H155.149V29.5569H145.324V42.5537H155.381V40.1361H148.322V37.1384Z"
          fill="white"
        />
        <path
          d="M168.069 36.3648C167.721 36.1134 167.315 35.92 166.831 35.7846C167.063 35.6685 167.276 35.5525 167.469 35.3978C168.262 34.7982 168.649 33.9666 168.649 32.9028C168.649 31.8198 168.224 30.9881 167.392 30.4079C166.56 29.8277 165.381 29.5376 163.872 29.5376H157.818V42.5537H164.22C165.864 42.5537 167.121 42.225 167.972 41.5867C168.842 40.9291 169.268 40.0395 169.268 38.8984C169.268 37.796 168.881 36.945 168.069 36.3648ZM160.816 31.7811H163.621C164.278 31.7811 164.781 31.9165 165.11 32.1872C165.439 32.4387 165.613 32.8448 165.613 33.3477C165.613 33.8699 165.439 34.2567 165.11 34.5274C164.781 34.7789 164.278 34.9143 163.621 34.9143H160.816V31.7811ZM165.69 39.9234C165.323 40.1942 164.8 40.3296 164.104 40.3296H160.797V37.0417H164.104C164.8 37.0417 165.323 37.1771 165.69 37.4479C166.057 37.6993 166.232 38.1054 166.232 38.647C166.232 39.2272 166.057 39.6527 165.69 39.9234Z"
          fill="white"
        />
        <path d="M66.5313 41.2H0.193405V43.134H66.5313V41.2Z" fill="white" />
        <path
          d="M53.7085 18.9971L49.5503 14.8389C49.4536 14.7422 49.2989 14.7422 49.2022 14.8389L45.044 18.9971C44.9473 19.0938 44.9473 19.2485 45.044 19.3452L49.2022 23.5034C49.2989 23.6001 49.4536 23.6001 49.5503 23.5034L53.7085 19.3452C53.8052 19.2485 53.8052 19.0938 53.7085 18.9971Z"
          fill="white"
        />
        <path
          d="M20.8684 18.9971L16.7102 14.8389C16.6135 14.7422 16.4588 14.7422 16.3621 14.8389L12.2039 18.9971C12.1071 19.0938 12.1071 19.2485 12.2039 19.3452L16.3621 23.5034C16.4588 23.6001 16.6135 23.6001 16.7102 23.5034L20.8684 19.3452C20.9651 19.2485 20.9651 19.0938 20.8684 18.9971Z"
          fill="white"
        />
      </svg>
    </div>
    <!-- <div class="footer__social">
      <a href="http://"
        ><svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_9_1487)">
            <path
              d="M29.6382 18.0812C28.7086 22.6682 26.0337 26.0457 21.9275 28.2767C21.0508 28.751 20.1229 29.1236 19.1617 29.3873V18.3031H22.3603C22.5222 17.0536 22.6812 15.8221 22.8461 14.5517H19.1627C19.1627 13.5521 19.1207 12.5865 19.1767 11.625C19.2167 10.9123 19.6085 10.5254 20.3162 10.4175C20.853 10.3395 21.4047 10.3615 21.9495 10.3475C22.2783 10.3395 22.6082 10.3475 22.9391 10.3475V6.97098C22.2394 6.932 21.5467 6.86103 20.858 6.87102C20.1393 6.87102 19.4026 6.86403 18.7069 7.01496C16.6828 7.44777 15.3933 9.02609 15.2934 11.1452C15.2424 12.1577 15.2664 13.1743 15.2574 14.1879C15.2574 14.3028 15.2574 14.4168 15.2574 14.5637H12.0588V18.3241H15.2304V29.962C15.1948 29.9702 15.1588 29.9763 15.1224 29.98C15.0445 29.98 14.9675 29.98 14.8885 29.98C12.7235 29.957 10.6414 29.5322 8.69123 28.5806C4.05724 26.3309 1.23813 22.6722 0.233899 17.6044C0.124946 17.0516 0.0769666 16.4929 0 15.9291V14.0549C0.02299 13.863 0.0489788 13.6711 0.0699697 13.4792C0.422817 10.2396 1.66928 7.3818 3.85233 4.96885C6.16633 2.41096 9.03009 0.817646 12.4376 0.226902C12.9734 0.133942 13.5151 0.0749675 14.0549 0H15.9281C16.2849 0.0439809 16.6428 0.0809649 16.9976 0.130943C20.2162 0.586746 23.041 1.89518 25.353 4.17919C29.2543 8.03452 30.7277 12.7135 29.6382 18.0812Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9_1487">
              <rect width="29.987" height="29.983" fill="white" />
            </clipPath>
          </defs>
        </svg> </a
      ><a href="http://"
        ><svg
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_9_1491)">
            <path
              d="M30.7442 12.4106C29.9085 7.92956 27.5246 4.50205 23.6542 2.11508C21.683 0.909469 19.4487 0.200143 17.1431 0.0479792C17.0668 0.0369884 16.9913 0.0209603 16.9172 0L15.043 0C14.5032 0.0749675 13.9614 0.132942 13.4247 0.226902C9.96318 0.826642 7.06643 2.46093 4.73844 5.0838C2.60836 7.48376 1.3689 10.2955 1.06403 13.4942C0.687195 17.4714 1.70675 21.0809 4.18568 24.2295C6.6846 27.4091 9.97217 29.2803 13.9814 29.8561C19.8789 30.7037 25.9173 27.757 28.906 22.6012C30.0624 20.6357 30.7544 18.4318 30.9291 16.158C30.94 16.0813 30.9564 16.0055 30.9781 15.9311V14.0579C30.8981 13.5081 30.8451 12.9544 30.7442 12.4106ZM24.4639 19.2547C24.264 21.5816 22.5807 23.3059 20.2577 23.4698C18.3915 23.5998 16.5133 23.5898 14.6411 23.5898C13.5506 23.5898 12.4481 23.5718 11.3726 23.4179C9.2065 23.108 7.71315 21.4807 7.49225 19.3016C7.42482 18.6811 7.38879 18.0576 7.38429 17.4334C7.38429 15.3933 7.39029 13.3532 7.44327 11.3141C7.46178 10.7197 7.56787 10.1313 7.75813 9.56785C8.33188 7.85959 9.57434 6.87702 11.3266 6.56915C12.0585 6.44988 12.7991 6.39236 13.5406 6.39723C15.5697 6.39023 17.5999 6.41422 19.63 6.44321C20.394 6.44481 21.1489 6.60976 21.844 6.927C23.4433 7.67767 24.3 8.9911 24.4579 10.7104C24.5888 12.1357 24.5808 13.5751 24.6338 14.9975C24.5768 16.4289 24.5768 17.8463 24.4639 19.2547Z"
              fill="white"
            />
            <path
              d="M22.9376 11.112C22.8876 9.26481 21.7121 8.08632 19.8729 8.03734C17.274 7.96737 14.6705 7.96737 12.0623 8.03734C10.2631 8.08532 9.12358 9.2768 9.01462 11.078C8.93666 12.3775 8.93166 13.6709 8.89368 14.9923C8.93166 16.2918 8.93266 17.6242 9.01962 18.9466C9.10359 20.2121 9.65835 21.2356 10.9118 21.6894C11.4721 21.8869 12.061 21.9913 12.655 21.9983C14.8691 22.0343 17.0841 22.0293 19.2992 21.9983C19.8451 21.9941 20.3872 21.9079 20.9075 21.7424C22.2329 21.3066 22.8976 20.276 22.9306 18.9486C22.9966 16.3397 23.0066 13.7229 22.9376 11.112ZM15.9606 19.4084C15.0874 19.4047 14.235 19.1423 13.5108 18.6543C12.7867 18.1664 12.2234 17.4749 11.892 16.667C11.5607 15.8592 11.4761 14.9712 11.649 14.1153C11.822 13.2595 12.2446 12.474 12.8637 11.8582C13.4827 11.2424 14.2703 10.8238 15.1271 10.6553C15.9839 10.4868 16.8713 10.576 17.6775 10.9115C18.4836 11.2471 19.1722 11.814 19.6564 12.5407C20.1405 13.2673 20.3985 14.1212 20.3977 14.9943C20.3982 15.5763 20.2837 16.1526 20.0607 16.6901C19.8376 17.2276 19.5105 17.7157 19.0981 18.1263C18.6858 18.5369 18.1963 18.8619 17.6578 19.0827C17.1193 19.3034 16.5425 19.4154 15.9606 19.4124V19.4084ZM20.6016 11.4209C20.3975 11.4266 20.1962 11.3715 20.0236 11.2624C19.8509 11.1534 19.7146 10.9955 19.632 10.8087C19.5494 10.6219 19.5243 10.4148 19.5598 10.2137C19.5954 10.0126 19.69 9.82661 19.8316 9.67947C19.9732 9.53233 20.1554 9.43068 20.355 9.38747C20.5546 9.34425 20.7625 9.36143 20.9523 9.43681C21.1421 9.5122 21.3052 9.64237 21.4207 9.81076C21.5363 9.97915 21.5991 10.1781 21.6012 10.3823C21.6045 10.5165 21.5812 10.6501 21.5327 10.7753C21.4842 10.9004 21.4113 11.0148 21.3184 11.1117C21.2255 11.2086 21.1143 11.2862 20.9913 11.3399C20.8683 11.3937 20.7358 11.4225 20.6016 11.4249V11.4209Z"
              fill="white"
            />
            <path
              d="M18.8493 14.9937C18.8496 15.5617 18.6815 16.1169 18.3662 16.5893C18.0508 17.0616 17.6024 17.4297 17.0777 17.647C16.553 17.8644 15.9756 17.9211 15.4187 17.8102C14.8617 17.6992 14.3502 17.4254 13.9489 17.0235C13.5476 16.6217 13.2745 16.1098 13.1643 15.5527C13.0541 14.9955 13.1117 14.4182 13.3297 13.8938C13.5478 13.3694 13.9165 12.9215 14.3893 12.6068C14.8621 12.2921 15.4176 12.1248 15.9855 12.126C16.7449 12.1286 17.4724 12.4317 18.009 12.9691C18.5456 13.5064 18.8477 14.2343 18.8493 14.9937Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9_1491">
              <rect
                width="29.987"
                height="30"
                fill="white"
                transform="translate(0.987061)"
              />
            </clipPath>
          </defs>
        </svg> </a
      ><a href="http://"
        ><svg
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_9_1497)">
            <path
              d="M30.7311 12.4244C29.8915 7.95635 27.5125 4.52784 23.6442 2.15886C21.655 0.929395 19.466 0.259686 17.127 0.0797635C17.047 0.0697678 16.9771 0.0497765 16.8971 0.0297852H15.0179C14.4781 0.0997548 13.9384 0.159729 13.3986 0.259686C9.94011 0.869421 7.04137 2.49872 4.71238 5.12758C2.5933 7.51654 1.39382 10.3153 1.04397 13.484C1.02398 13.6739 0.99399 13.8638 0.973999 14.0637V15.9329C1.05396 16.4927 1.10394 17.0524 1.2039 17.6022C1.81364 21.0107 3.43293 23.8695 6.01182 26.1785C8.28083 28.2076 10.9497 29.437 13.9684 29.8269C18.1066 30.3566 21.8749 29.3671 25.1735 26.7882C28.3721 24.2993 30.2313 21.0107 30.8211 17.0024C30.871 16.6526 30.911 16.2927 30.951 15.9329V14.0637C30.881 13.5139 30.8311 12.9642 30.7311 12.4244ZM23.4643 13.5039C21.8749 13.5039 20.4656 13.0042 19.1461 12.0446V12.4044C19.1561 14.3236 19.1661 16.2428 19.1661 18.1619C19.1661 20.6908 17.5468 22.7599 15.0979 23.3897C12.1791 24.1393 9.20043 22.2901 8.55072 19.3314C7.92099 16.4727 9.87014 13.6039 12.8089 13.0641C13.3986 12.9542 13.9884 12.9342 14.5981 13.0441V15.8029C14.2582 15.783 13.9184 15.733 13.5885 15.753C12.2591 15.8329 11.2595 16.9724 11.2795 18.3418C11.2995 19.6313 12.3891 20.7208 13.6885 20.7808C15.0179 20.8408 16.1874 19.8512 16.3174 18.5518C16.3373 18.3718 16.3473 18.1819 16.3473 18.002C16.3473 14.2936 16.3473 10.5752 16.3573 6.86682V6.47699H19.1061C19.406 9.06587 20.8554 10.4853 23.4643 10.7751V13.5039Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9_1497">
              <rect
                width="29.987"
                height="29.997"
                fill="white"
                transform="translate(0.973999)"
              />
            </clipPath>
          </defs>
        </svg> </a
      ><a href="http://"
        ><svg
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_9_1500)">
            <path
              d="M26.589 4.44507C24.176 1.94616 21.1843 0.527771 17.7278 0.108953C17.4479 0.0749675 17.17 0.036984 16.8902 0H15.017C14.4682 0.0759671 13.9174 0.134942 13.3707 0.2299C10.2071 0.780662 7.50023 2.21504 5.2592 4.49805C3.08692 6.67883 1.65354 9.48616 1.16097 12.5246C1.07501 13.0324 1.02403 13.5451 0.96106 14.0559V15.9301C1.03803 16.4889 1.091 17.0516 1.19396 17.6064C1.97562 21.7952 4.13368 25.0858 7.66815 27.4781C10.249 29.2223 13.1238 30.014 16.2344 29.985C23.0485 29.92 29.2008 24.9312 30.5792 18.2581C31.6707 12.9754 30.3413 8.32739 26.589 4.44507ZM23.3024 10.3295C23.0215 11.6949 22.7296 13.0583 22.4398 14.4218C21.9193 16.8714 21.3979 19.3213 20.8754 21.7716C20.6685 22.7421 20.1208 22.9561 19.3251 22.3713C18.2016 21.545 17.0781 20.7184 15.9546 19.8914C15.8916 19.8454 15.8266 19.8034 15.7467 19.7494L13.8925 21.5357C13.7055 21.7166 13.5036 21.8605 13.1928 21.8355C13.2217 21.3857 13.2497 20.9359 13.2797 20.4941C13.3297 19.7944 13.3947 19.0947 13.4237 18.395C13.4243 18.2798 13.4507 18.1662 13.5008 18.0624C13.551 17.9587 13.6236 17.8674 13.7135 17.7953C15.8253 15.8968 17.934 13.9943 20.0398 12.0878C20.1449 12.0009 20.2451 11.9081 20.3397 11.8099C20.3985 11.7287 20.4494 11.642 20.4916 11.551C20.3756 11.537 20.2487 11.488 20.1447 11.518C19.9986 11.5718 19.8604 11.6451 19.7339 11.7359C17.0351 13.4338 14.3363 15.1331 11.6374 16.8337C11.5479 16.8971 11.4442 16.9374 11.3354 16.9512C11.2267 16.965 11.1162 16.9517 11.0137 16.9127C9.92018 16.5598 8.82165 16.223 7.72613 15.8751C7.43825 15.7842 7.08541 15.7082 7.07141 15.3443C7.05742 14.9805 7.38827 14.8446 7.67115 14.7346C8.87063 14.2558 10.0861 13.794 11.2946 13.3272L21.9839 9.20601C22.3488 9.06507 22.7266 8.87815 23.0835 9.18502C23.4403 9.49189 23.3853 9.9257 23.3024 10.3295Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9_1500">
              <rect
                width="29.987"
                height="29.986"
                fill="white"
                transform="translate(0.96106)"
              />
            </clipPath>
          </defs>
        </svg> </a
      ><a href="http://"
        ><svg
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_9_1504)">
            <path
              d="M15.9315 0.0800893C7.59511 0.090085 0.928004 6.79718 0.938 15.1636C0.947995 23.42 7.68508 30.0871 16.0115 30.0771C24.2479 30.0671 30.945 23.32 30.925 15.0336C30.915 6.77719 24.1879 0.0700936 15.9315 0.0800893ZM15.9115 24.4595C10.7837 24.4695 6.56556 20.2414 6.55556 15.1036C6.54557 9.92582 10.7438 5.70765 15.9215 5.69765C21.0793 5.68766 25.3074 9.90583 25.3174 15.0536C25.3174 20.2414 21.1093 24.4595 15.9115 24.4595Z"
              fill="white"
            />
            <path
              d="M19.54 17.3424C19.53 17.4124 19.5 17.4823 19.48 17.5623C19.3801 17.5723 19.2901 17.5723 19.1901 17.5723C17.0111 17.5723 14.832 17.5723 12.653 17.5823C12.4431 17.5823 12.3531 17.5323 12.3231 17.3124C12.1432 15.813 12.1332 14.3137 12.3331 12.8143C12.3431 12.7444 12.3731 12.6744 12.3931 12.5944C12.483 12.5844 12.563 12.5744 12.653 12.5744C14.842 12.5744 17.0311 12.5744 19.2201 12.5645C19.43 12.5645 19.52 12.6144 19.55 12.8343C19.7299 14.3437 19.7299 15.843 19.54 17.3424Z"
              fill="white"
            />
            <path
              d="M19.2501 11.3154H12.6229C12.9928 9.92599 13.4626 8.60656 14.5221 7.597C15.4617 6.69739 16.4713 6.72737 17.4009 7.64698C18.1805 8.41664 18.6303 9.38622 18.9802 10.4058C19.0801 10.7057 19.1501 10.9955 19.2501 11.3154Z"
              fill="white"
            />
            <path
              d="M19.2501 18.842C18.8502 20.3014 18.3604 21.6908 17.181 22.7004C16.3713 23.3901 15.4717 23.41 14.692 22.6804C14.2422 22.2505 13.8324 21.7408 13.5325 21.191C13.1427 20.4613 12.8828 19.6617 12.543 18.832H19.2501V18.842Z"
              fill="white"
            />
            <path
              d="M23.7281 17.3426C23.6981 17.4326 23.5782 17.5625 23.4982 17.5625C22.5986 17.5825 21.699 17.5725 20.7494 17.5725C20.9893 15.8933 20.9893 14.264 20.7494 12.5747H21.4191C22.0888 12.5747 22.7485 12.5847 23.4182 12.5747C23.5982 12.5747 23.6781 12.6247 23.7281 12.8046C24.1679 14.314 24.1779 15.8333 23.7281 17.3426Z"
              fill="white"
            />
            <path
              d="M11.1136 17.5723H10.0141C9.49435 17.5723 8.97457 17.5623 8.4548 17.5723C8.27488 17.5723 8.17492 17.5324 8.11494 17.3324C7.68513 15.8231 7.68513 14.3038 8.12494 12.7944C8.15493 12.7044 8.26488 12.5845 8.33485 12.5745C9.24446 12.5545 10.1541 12.5645 11.1036 12.5645C10.8737 14.2638 10.8737 15.9031 11.1136 17.5723Z"
              fill="white"
            />
            <path
              d="M12.8528 7.59668C12.1332 8.74618 11.6234 9.96565 11.3335 11.3051H8.74463C9.69422 9.56583 11.0436 8.33636 12.8528 7.59668Z"
              fill="white"
            />
            <path
              d="M23.1283 11.305H20.5795C20.3595 10.6453 20.1796 9.99558 19.9197 9.37585C19.6698 8.76612 19.33 8.18637 19.0101 7.55664C20.8193 8.3363 22.1688 9.56577 23.1283 11.305Z"
              fill="white"
            />
            <path
              d="M23.1283 18.8418C22.1688 20.591 20.8193 21.8205 19.0101 22.5602C19.7298 21.4107 20.2396 20.1812 20.5295 18.8418H23.1283Z"
              fill="white"
            />
            <path
              d="M12.8528 22.5602C11.0536 21.8305 9.6942 20.591 8.73462 18.8418H11.3335C11.6234 20.1712 12.1331 21.4107 12.8528 22.5602Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_9_1504">
              <rect
                width="29.987"
                height="29.987"
                fill="white"
                transform="translate(0.947998)"
              />
            </clipPath>
          </defs>
        </svg>
      </a>
    </div> -->
    <div class="footer__policy">
      <router-link :to="{ name: 'policy' }" target="_blank">
        Публічний договір</router-link
      >
    </div>
  </footer>
</template>
